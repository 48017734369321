/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import moment from "moment";
import {
  ArticleList,
  Button,
  CategoryGrid,
  Footer,
  Grid,
  Layout
} from "~components";
import { useApp, useAuth, useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

const LibraryPage = ({ data, location }) => {
  // ==========================================================================
  // context / ref / state

  const { setRegisterActive } = useApp();
  const { loggedIn } = useAuth();
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  // ==========================================================================
  // variables

  const {
    allSanityArticle,
    allSanityCategory,
    allSanityEvent,
    sanityLibraryPage
  } = data;

  // const publicArticles = allSanityArticle.edges.filter(
  //   ({ node }) => node.availability === -1
  // );

  const articles = allSanityArticle.edges.map(({ node }) => node);
  const categories = allSanityCategory.edges.map(({ node }) => node);
  const events = allSanityEvent.edges.map(({ node }) => ({
    type: {
      title: `event`
    },
    ...node
  }));

  const libraryItems = [...articles, ...events].sort((a, b) => {
    const dateA = a?.date ? moment(a.date) : moment(`01/01/2020`);
    const dateB = b?.date ? moment(b.date) : moment(`01/01/2020`);

    if (dateA > dateB) {
      return -1;
    }

    if (dateA < dateB) {
      return 1;
    }

    return 0;
  });

  // ==========================================================================
  // render

  return (
    <>
      <Layout>
        <section
          css={[
            css`
              ${colourTransitionCSS};

              background: ${lowImpactMode
                ? colours.foreground
                : colours.background};
              color: ${lowImpactMode ? colours.background : colours.foreground};

              padding-top: 56px;

              ${MEDIA_QUERIES?.desktop} {
                padding-top: 90px;
              }
            `
          ]}
        >
          <div
            css={[
              css`
                animation: 1s ease appear-down forwards;
              `,
              tw`relative pt-16 md:pt-24 pb-12 md:pb-24`
            ]}
          >
            <Grid>
              <article css={[tw`col-span-12 md:col-span-10 md:col-start-2`]}>
                <h1
                  css={[
                    css`
                      letter-spacing: 0.03em !important;
                      font-weight: 100 !important;
                    `,
                    tw`font-inline text-h4 md:text-h4-md uppercase`
                  ]}
                >
                  The Library
                </h1>

                <p css={[tw`mt-6 font-body text-h3 md:text-h2-md`]}>
                  {sanityLibraryPage.heading}
                </p>

                {!loggedIn && (
                  <Button
                    _css={[tw`h-10 md:h-auto relative block mt-6 px-8`]}
                    onClick={() => setRegisterActive(true)}
                    color={
                      lowImpactMode ? colours.background : colours.foreground
                    }
                    text="Sign up"
                  />
                )}
              </article>
            </Grid>
          </div>
        </section>

        <div css={[tw`relative pb-32 bg-grey-tint`]}>
          <CategoryGrid
            categories={categories}
            heading={sanityLibraryPage.categoryGridHeading}
          />

          <ArticleList articles={libraryItems} withHeading />
        </div>
      </Layout>

      <Footer />
    </>
  );
};

export default LibraryPage;

export const query = graphql`
  query LibraryPage {
    sanityLibraryPage {
      heading
      categoryGridHeading
    }

    allSanityArticle(sort: { order: DESC, fields: [date, priority] }) {
      edges {
        node {
          _id
          cost

          date
          priority
          author
          availability
          title
          excerpt
          readTime

          slug {
            current
          }

          theme {
            backgroundColour {
              hex
            }
            foregroundColour {
              hex
            }
            buttonColour
          }

          cardImage {
            asset {
              gatsbyImageData(width: 1200, placeholder: NONE)
            }
            altText
          }
        }
      }
    }

    allSanityEvent(sort: { order: DESC, fields: [date, priority] }) {
      edges {
        node {
          _id
          cost

          date
          priority
          title
          excerpt

          slug {
            current
          }

          theme {
            backgroundColour {
              hex
            }
            foregroundColour {
              hex
            }
            buttonColour
          }

          cardImage {
            asset {
              gatsbyImageData(width: 1200, placeholder: NONE)
            }
            altText
          }
        }
      }
    }

    allSanityCategory {
      edges {
        node {
          slug {
            current
          }

          name
          description
        }
      }
    }
  }
`;
